$brownish-grey: #666666;

$bdr-white: #ffffff;
$bdr-white-shade: rgba($bdr-white, 0.54);
$bdr-white-light: #fafafa;

$red: #ff0000;
$black: #444444;
$real-black: #000;
$color-separator: #ddd;
$bdr-red: #ce1b24;
$bdr-red-shade: rgba($bdr-red, 0.54);

$bdr-green: #a2ae00;

$bdr-yellow-orange: #ffcc00;
$bdr-dark-blue: #004165;
$bdr-mid-blue: #4096af;
$bdr-light-blue: #78b9d0;
$bdr-gray-darkest: #666666;
$bdr-gray-cancel: #6b6b6b;
$bdr-gray-dark: #c0c0c0;
$bdr-gray: #e0e0e0;
$bdr-gray-light: #e6e6e6;
$bdr-gray-lightest: #eeeeee;
$bdr-gray-placeholder: #adadad;

$bdr-input-text: #666666; // ADADAD
$bdr-disabled-input-text: #cccccc;
$bdr-input-inner-border: #cccccc;
$bdr-focused-input-outer-border: #999999;
$bdr-disabled-input-inner-border: #e7e7e7;
$bdr-disabled-input-outer-border: #f6f6f6;
$bdr-input-dotted-color-border: #cbcbcb;
$bdr-bottom-border: #f4f4f4;

$bdr-pumpkin-dark: #be6700;
$bdr-pumpkin: #ef8200;
$bdr-second-pumpkin: #e39e20;
$bdr-pumpkin-light: #f9cd99;
$bdr-orange-link: #e98300;

// $bdr-error: #e9573f;
$bdr-error: #d0021b;
$bdr-success: #008e00;

$bdr-right-shadow: 1px 0 $bdr-gray, 2px 0 rgba($bdr-gray-lightest, 0.6);
$bdr-bottom-shadow: 0 1px $bdr-gray-light, 0 2px $bdr-white;

$bdr-header-bg: #004165;
$bdr-header-color: rgba($bdr-white, 0.54);
$bdr-fake-icon-bg: #728fa0;
$bdr-fake-icon-bg-hover: #1e5f72;
$bdr-fake-icon-color: #ffffff;
$bdr-fake-icon-color-hover: #ffffff;
$bdr-fake-icon-manual-color: #2298af;
$bdr-fake-icon-manual-hover-bg: #2298af;
$bdr-fake-icon-manual-hover-color: #ffffff;

//colors
$bdr-login-header-bg: #f5f5f5;
$bdr-login-header-color: #414141;
$bdr-login-header-p-color: #b8b8b8;
$bdr-item-list-border: #e0e0e0;
$bdr-item-list-shadow-no-opacity: #eeeeee;
$bdr-item-list-shadow: rgba($bdr-item-list-shadow-no-opacity, 0.6);
$bdr-item-list-header-line: #dddddd;

$bdr-icon: #c0c0c0;
$bdr-icon-hover: #e98300;

$bdr-color-font: #666;
$bdr-scrollbar: #ddd;

$bdr-circle-1: #e7e7e1;
$bdr-circle-2: #eeeee7;
$bdr-circle-3: #f8f8f8;

$bdr-filter-title: #7c7c7c;
$bdr-filter-icon-bg: #80a88f;
$bdr-rules-icon-bg: #a17b92;
$bdr-procedures-icon-bg: #a17b92;

$qb-dark-grey: #999;
$qb-light-grey: #ddd;
$qb-pumpkin: #e98300;
$qb-pumpkin-dark: #d97a00;
$qb-shadow-grey: #ccc;

$dialog-shadow-grey-50: rgba(#ccc, 0.5);
$dialog-dark-grey: #999;
$dialog-border-grey: #dddddd;
$dialog-light-grey: #f1f1f1;
$dialog-white-60: rgba($bdr-white, 0.6);
$dialog-header-border: #cccccc;

$tab-color: #848484;
$tab-color-active: #447198;

$bdr-item-list-border: #e0e0e0;
$bdr-item-list-shadow-no-opacity: #eeeeee;
$bdr-item-list-shadow: rgba($bdr-item-list-shadow-no-opacity, 0.6);
$bdr-item-list-header-line: #dddddd;

$bdr-manual-validations-row-bg: rgba(34, 152, 175, 0.2);
$bdr-manual-validations-row-color: rgba(34, 152, 175, 1);

$bdr-automatic-validations-row-bg: rgba(30, 95, 114, 0.2);
$bdr-automatic-validations-row-color: rgba(30, 95, 114, 1);

$bdr-select-arrow-icon-color: #9c9c9c;
$bdr-notif-save: #009ab2;

// Closing Works
$bdr-text-disabled: #999999;
$bdr-title-color: #4a4a4a;
$bdr-header-table-color-text: #4a4a4a;
$bdr-btn-grey: #4a4a4a;
$bdr-text-color-orange: #ef8200;
$bdr-text-color-black: #0d0d0d;
$bdr-table-even-color: #fafafa;
$bdr-table-odd-color: #ffffff;
$bdr-table-editable-cell: #ef8200;
