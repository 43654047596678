@mixin ag-theme-base-part-footer {
    .ag-paging-panel {
        border-top: 1px solid;
        @include ag-color-property(border-top-color, border-color);
        @include ag-color-property(color, secondary-foreground-color);
        height: ag-param(header-height);

        > * {
            margin: 0 ag-param(cell-horizontal-padding);
        }
    }

    .ag-paging-button {
        cursor: pointer;
    }

    .ag-paging-button.ag-disabled {
        cursor: default;
        @include ag-color-property(color, disabled-foreground-color);
    }

    @include ag-keyboard-focus((ag-paging-button), 0px);

    .ag-paging-button,
    .ag-paging-description {
        margin: 0 ag-param(grid-size);
    }

    .ag-status-bar {
        @include ag-border(primary, top);
        @include ag-color-property(color, disabled-foreground-color);
        padding-right: ag-param(grid-size) * 4;
        padding-left: ag-param(grid-size) * 4;
        line-height: 1.5;
    }

    .ag-status-name-value-value {
        @include ag-color-property(color, foreground-color);
    }

    .ag-status-bar-center {
        text-align: center;
    }

    .ag-status-name-value {
        margin-left: ag-param(grid-size);
        margin-right: ag-param(grid-size);
        padding-top: ag-param(grid-size) * 2;
        padding-bottom: ag-param(grid-size) * 2;
    }
}
