@font-face {
  font-family: "esir";
  src: url("../../assets/fonts/esir.eot?jqrk5p");
  src: url("../../assets/fonts/esir.eot?jqrk5p#iefix") format("embedded-opentype"),
    url("../../assets/fonts/esir.ttf?jqrk5p") format("truetype"),
    url("../../assets/fonts/esir.woff?jqrk5p") format("woff"),
    url("../../assets/fonts/esir.svg?jqrk5p#esir") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon.esir {
  font-family: "esir" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.icon-caret-up:before {
  content: "\e900";
}
.icon-caret-down:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-notification-active:before {
  content: "\e903";
}
.icon-menu:before {
  content: "\e904";
}
.icon-issues:before {
  content: "\e905";
}
.icon-volumetries:before {
  content: "\e906";
}
.icon-meters:before {
  content: "\e907";
}
.icon-concentrators:before {
  content: "\e908";
}
.icon-reports:before {
  content: "\e909";
}
.icon-expand-menu:before {
  content: "\e90a";
}
.icon-reduce-menu:before {
  content: "\e90b";
}
.icon-plans:before {
  content: "\e90c";
}
.icon-asset-rules:before {
  content: "\e90d";
}
.icon-assignation-rules:before {
  content: "\e90e";
}
.icon-report-rules:before {
  content: "\e90f";
}
.icon-flow-rules:before {
  content: "\e910";
}
.icon-filters:before {
  content: "\e911";
}
.icon-manage-label:before {
  content: "\e912";
}
.icon-no-asset-rules:before {
  content: "\e913";
}
.icon-no-plans:before {
  content: "\e914";
}
.icon-no-filters:before {
  content: "\e915";
}
.icon-print:before {
  content: "\e916";
}
.icon-edit:before {
  content: "\e917";
}
.icon-cancel-search:before {
  content: "\e918";
}
.icon-close-popup:before {
  content: "\e919";
}
.icon-keyboard-search:before {
  content: "\e91a";
}
.icon-search:before {
  content: "\e91b";
}
.icon-add-label:before {
  content: "\e91c";
}
.icon-add:before {
  content: "\e91d";
}
.icon-label:before {
  content: "\e91e";
}
.icon-comments:before {
  content: "\e91f";
}
.icon-scroll-top:before {
  content: "\e920";
}
.icon-contact-admin:before {
  content: "\e921";
}
.icon-resize-textarea:before {
  content: "\e922";
}
.icon-arrow-up:before {
  content: "\e923";
}
.icon-arrow-down:before {
  content: "\e924";
}
.icon-email:before {
  content: "\e925";
}
.icon-empty-color:before {
  content: "\e926";
}
.icon-check:before {
  content: "\e927";
}
.icon-cancel:before {
  content: "\e928";
}
.icon-close:before {
  content: "\e929";
}
.icon-calendar:before {
  content: "\e92a";
}
.icon-prev-month:before {
  content: "\e92b";
}
.icon-next-month:before {
  content: "\e92c";
}
.icon-grid-views:before {
  content: "\e92d";
}
.icon-dashboard-views:before {
  content: "\e92e";
}
.icon-volumetries-dotted:before {
  content: "\e92f";
}
.icon-filters-dotted:before {
  content: "\e930";
}
.icon-download-dotted:before {
  content: "\e931";
}
.icon-close-dotted:before {
  content: "\e932";
}
.icon-thumbs-up:before {
  content: "\e933";
}
.icon-thumbs-down:before {
  content: "\e934";
}
.icon-info:before {
  content: "\e935";
}
.icon-alert:before {
  content: "\e936";
}
.icon-minimize-popup:before {
  content: "\e937";
}
.icon-plans-structure:before {
  content: "\e938";
}
.icon-tree:before {
  content: "\e939";
}
.icon-tree-remove:before {
  content: "\e93a";
}
.icon-remove:before {
  content: "\e93b";
}
.icon-folder-remove:before {
  content: "\e93c";
}
.icon-folder-add:before {
  content: "\e93d";
}
.icon-folder:before {
  content: "\e93e";
}
.icon-bullet:before {
  content: "\e940";
}
.icon-flux-rule:before {
  content: "\e941";
}
.icon-assignation-rule:before {
  content: "\e942";
}
.icon-rule:before {
  content: "\e943";
}
.icon-plan:before {
  content: "\e944";
}
.icon-filter:before {
  content: "\e945";
}
.icon-joint:before {
  content: "\e946";
}
.icon-drag:before {
  content: "\e947";
}
.icon-expand-rule:before {
  content: "\e948";
}
.icon-deactivate-rule:before {
  content: "\e949";
}
.icon-activate-plan:before {
  content: "\e94a";
}
.icon-duplicate:before {
  content: "\e94b";
}
.icon-activate:before {
  content: "\e94c";
}
.icon-download:before {
  content: "\e94d";
}
.icon-share:before {
  content: "\e94e";
}
.icon-color-picker-selected:before {
  content: "\e94f";
}
.icon-color-picker:before {
  content: "\e950";
}
.icon-move:before {
  content: "\e951";
}
.icon-fixed:before {
  content: "\e952";
}
.icon-table-drag:before {
  content: "\e953";
}
.icon-table-fixed:before {
  content: "\e954";
}
.icon-detection:before {
  content: "\e93f";
}
.icon-menu-grids:before {
  content: "\e955";
}
.icon-pin:before {
  content: "\e956";
}
.icon-rename-tag:before {
  content: "\e957";
}
.icon-change-tag:before {
  content: "\e958";
}
.icon-remove-tag:before {
  content: "\e959";
}
