@import "../../ag-theme-base/sass/ag-theme-base";
@import "./ag-theme-balham-default-params";

@mixin ag-theme-balham($params: ()) {
    @include ag-allow-color-param-access-with-ag-param(false);

    $params: ag-process-theme-variables($params, $ag-theme-balham-default-params);
    @include ag-theme-base($params);

    .ag-filter-toolpanel-header,
    .ag-filter-toolpanel-search,
    .ag-status-bar,
    .ag-header-row,
    .ag-multi-filter-group-title-bar {
        font-weight: 600;
        @include ag-color-property(color, header-foreground-color);
    }

    %ag-text-input {
        @include ag-theme-rtl(
            (
                padding-left: ag-param(grid-size),
            )
        );
    }

    .ag-column-drop-vertical-empty-message,
    .ag-status-bar {
        font-weight: 600;
        @include ag-color-property(color, disabled-foreground-color);
    }
    .ag-dnd-ghost {
        font-weight: 600;
    }

    .ag-tab {
        border: 1px solid transparent;
        padding: ag-param(grid-size) ag-param(grid-size) * 2;
        margin: ag-param(grid-size);
        margin-bottom: -1px; // shift down 1px to make tab background blend with area below
    }

    .ag-tab-selected {
        @include ag-color-property(background-color, background-color);
        @include ag-color-property(border-color, border-color);
        border-bottom-color: transparent;
    }

    .ag-tabs-header {
        border-bottom: 1px solid;
        @include ag-color-property(border-bottom-color, border-color);
    }

    .ag-column-drop-cell {
        height: ag-param(grid-size) * 6;
    }

    .ag-column-drop-vertical-title {
        @include ag-color-property(color, foreground-color);
    }

    .ag-column-drop-vertical-cell {
        margin-left: ag-param(grid-size) * 2;
        margin-right: ag-param(grid-size) * 2;
    }

    .ag-column-drop-vertical-cell-text {
        margin-left: ag-param(grid-size) * 2;
    }

    .ag-column-drop-vertical-icon {
        @include ag-color-property(color, secondary-foreground-color);
    }

    .ag-column-drop-vertical-empty-message {
        @include ag-theme-rtl(
            (
                padding-left: ag-param(icon-size) + ag-param(grid-size) * 2,
                padding-right: ag-param(grid-size),
            )
        );
    }

    .ag-column-drop-horizontal {
        height: ag-param(header-height);
    }

    .ag-column-drop-empty {
        @include ag-color-property(color, disabled-foreground-color);
    }

    .ag-column-drop-horizontal-cell-text {
        margin-left: ag-param(grid-size) * 2;
    }

    .ag-column-drop-vertical {
        padding-top: ag-param(grid-size) * 2;
    }

    .ag-menu-header {
        @include ag-color-property(background-color, header-background-color);
    }

    .ag-overlay-loading-center {
        @include ag-color-property(background-color, background-color);
        border: 1px solid;
        @include ag-color-property(border-color, border-color);
        @include ag-color-property(color, foreground-color);
        padding: ag-param(grid-size) * 4;
    }

    .ag-tooltip {
        border: none;
        background-color: #cbd0d3;
    }

    .ag-panel-title-bar-button-icon {
        font-size: ag-param(icon-size) + ag-param(grid-size);
    }

    .ag-chart-data-section,
    .ag-chart-format-section {
        padding-bottom: ag-param(grid-size) * 0.5;
    }

    .ag-group-toolbar {
        @include ag-color-property(background-color, subheader-toolbar-background-color);
    }

    .ag-chart-tab {
        padding-top: ag-param(grid-size) * 0.5;
    }

    .ag-charts-format-sub-level-group-item {
        margin-bottom: ag-param(grid-size) * 1.5;
    }
    @include ag-allow-color-param-access-with-ag-param(true);
}
