@import "./colors";

$helvetica-neue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$arial: Arial, sans-serif;

@mixin title-login {
  font-size: 24px;
  font-family: $helvetica-neue;
  color: $bdr-gray-darkest;
  font-weight: normal;
}

@mixin title-main {
  font-size: 16px;
  font-family: $helvetica-neue;
  color: $bdr-gray-darkest;
  font-weight: bold;
}

@mixin title-container {
  font-size: 18px;
  font-family: $helvetica-neue;
  color: $bdr-gray-darkest;
  font-weight: bold;
}

@mixin title-menu {
  font-size: 16px;
  font-family: $helvetica-neue;
  color: $bdr-gray-darkest;
  font-weight: bold;
}

@mixin subtitle-login {
  font-size: 14px;
  font-family: $helvetica-neue;
  color: $bdr-gray-darkest;
  font-weight: normal;
}

@mixin subtitle-menu {
  font-size: 14px;
  font-family: $helvetica-neue;
  color: $bdr-gray-darkest;
  font-weight: bold;
}

@mixin text {
  font-size: 13px;
  font-family: $arial;
  color: $bdr-gray-darkest;
  font-weight: normal;
}

@mixin error-text {
  font-size: 13px;
  font-family: $arial;
  color: $bdr-error;
  font-weight: bold;
}

@mixin input-label {
  font-size: 13px;
  font-family: $arial;
  color: $bdr-gray-darkest;
  font-weight: bold;
}

@mixin input-error-label {
  font-size: 13px;
  font-family: $arial;
  color: $bdr-error;
  font-weight: bold;
}

@mixin select-option-list {
  font-size: 11px;
  font-family: $arial;
  color: $bdr-gray-darkest;
  font-weight: normal;
}

@mixin text-medium-bold {
  font-size: 11px;
  font-family: $arial;
  color: $bdr-gray-darkest;
  font-weight: bold;
}

@mixin link {
  font-size: 13px;
  font-family: $arial;
  color: $bdr-pumpkin;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@mixin link-container {
  @include link;
  font-size: 14px;
  font-weight: bold;
}

@mixin icon-link {
  color: $bdr-gray-dark;
  cursor: pointer;

  &:hover {
    color: $bdr-pumpkin;
  }
}

@mixin fake-icon-hover {
  color: $bdr-fake-icon-color-hover;
  background-color: $bdr-fake-icon-bg-hover;
}

@mixin fake-icon-manual-validations-hover {
  color: $bdr-fake-icon-manual-hover-color;
  background-color: $bdr-fake-icon-manual-hover-bg;
}

@mixin icon {
  font-family: "bdr_ico" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

@mixin bdr-button {
  font-weight: 500;
  border: none;
  height: 3.5rem;
  font-size: 1rem;
  padding: 0 2.1rem;
  display: inline-block;
  background: #ffffff;
  border-radius: 0.5rem;
  color: #828282;

  &:focus {
    outline: none;
  }

  &.primary {
    background-color: $bdr-pumpkin;
    color: $bdr-white;
  }
}
