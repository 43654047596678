@mixin ag-theme-base-part-date-time {
    .ag-date-time-list-page-entry-is-padding {
        // TODO apply padding style (greyed out) in theme css, using
        // mixin like for ag-native-inputs()
    }

    .ag-date-time-list-page-title {
        flex-grow: 1;
        text-align: center;
    }

    .ag-date-time-list-page-column-label {
        text-align: center;
    }

    .ag-date-time-list-page-entry {
        text-align: center;
    }
}
