@mixin ag-theme-base-part-filter-tool-panel {
    .ag-filter-toolpanel-header {
        height: ag-param(grid-size) * 6;
    }

    .ag-filter-toolpanel-header,
    .ag-filter-toolpanel-search {
        @include ag-theme-rtl(
            (
                padding-left: ag-param(grid-size),
            )
        );
    }

    @include ag-keyboard-focus((ag-filter-toolpanel-header), 4px);

    .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after {
        @include ag-icon();
        @include ag-theme-rtl(
            (
                padding-left: ag-param(grid-size),
            )
        );
        content: ag-icon-char(filter);
        position: absolute;
    }

    .ag-filter-toolpanel-group-level-0-header {
        height: ag-param(grid-size) * 8;
    }

    .ag-filter-toolpanel-group-item {
        margin-top: ag-param(grid-size) * 0.5;
        margin-bottom: ag-param(grid-size) * 0.5;
    }

    .ag-filter-toolpanel-search {
        height: ag-param(header-height);
    }

    .ag-filter-toolpanel-search-input {
        flex-grow: 1;
        height: ag-param(grid-size) * 4;
        @include ag-theme-rtl(
            (
                margin-right: ag-param(grid-size),
            )
        );
    }

    .ag-filter-toolpanel-group-level-0 {
        @include ag-border(secondary, top);
    }

    .ag-filter-toolpanel-expand,
    .ag-filter-toolpanel-group-title-bar-icon {
        @include ag-theme-rtl(
            (
                margin-right: ag-param(grid-size),
            )
        );
    }

    @for $i from 1 through 10 {
        .ag-filter-toolpanel-group-level-#{$i} {
            .ag-filter-toolpanel-group-level-#{$i}-header {
                &.ag-filter-toolpanel-group-title-bar {
                    background-color: transparent;
                }
            }
            .ag-filter-toolpanel-group-level-#{$i + 1}-header {
                @include ag-theme-rtl(
                    (
                        padding-left: ag-param(filter-tool-panel-group-indent) * $i + ag-param(grid-size),
                    )
                );
            }
        }
    }

    .ag-filter-toolpanel-instance-header.ag-filter-toolpanel-group-level-1-header {
        padding-left: ag-param(grid-size);
    }

    .ag-filter-toolpanel-instance-filter {
        @include ag-border(primary, (top, bottom));
        margin-top: ag-param(grid-size);
    }

    .ag-filter-toolpanel-instance-header-icon {
        @include ag-theme-rtl(
            (
                margin-left: ag-param(grid-size),
            )
        );
    }
}
