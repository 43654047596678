@mixin ag-theme-base-part-charts {
    .ag-chart-menu {
        border-radius: ag-param(card-radius);
        @include ag-color-property(background, background-color);
    }

    .ag-chart-menu-icon {
        opacity: 0.5;
        line-height: 24px;
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin: 2px 0;
        cursor: pointer;
        border-radius: ag-param(card-radius);
        @include ag-color-property(color, secondary-foreground-color);

        &:hover {
            opacity: 1;
        }
    }

    .ag-chart-mini-thumbnail {
        border: 1px solid;
        @include ag-color-property(border-color, secondary-border-color);
        border-radius: 5px;
        margin: 5px;

        // specific style to apply when there are 3 items in the row
        &:nth-last-child(3),
        &:nth-last-child(3) ~ .ag-chart-mini-thumbnail {
            margin-left: auto;
            margin-right: auto;
        }

        &:first-child {
            @include ag-theme-rtl(
                (
                    margin-left: 0,
                )
            );
        }

        &:last-child {
            @include ag-theme-rtl(
                (
                    margin-right: 0,
                )
            );
        }

        &.ag-selected {
            @include ag-color-property(border-color, minichart-selected-chart-color);
        }
    }

    .ag-chart-settings-card-item {
        @include ag-color-property(background, foreground-color);
        width: 8px;
        height: 8px;
        border-radius: 4px;

        &.ag-selected {
            @include ag-color-property(background-color, minichart-selected-page-color);
        }
    }

    .ag-chart-data-column-drag-handle {
        margin-left: ag-param(grid-size);
    }

    .ag-charts-settings-group-title-bar,
    .ag-charts-data-group-title-bar,
    .ag-charts-format-top-level-group-title-bar {
        @include ag-border(secondary, top);
    }

    .ag-charts-settings-group-container {
        padding: ag-param(grid-size);
    }

    .ag-charts-data-group-container {
        @include ag-vertical-widget-container();
    }

    .ag-charts-format-top-level-group-container {
        margin-left: ag-param(grid-size) * 2;
        padding: ag-param(grid-size);
    }

    .ag-charts-format-top-level-group-item {
        margin: ag-param(grid-size) 0;
    }

    .ag-charts-format-sub-level-group-container {
        @include ag-vertical-widget-container();
    }

    .ag-charts-group-container.ag-group-container-horizontal {
        padding: ag-param(grid-size);
    }

    .ag-chart-data-section,
    .ag-chart-format-section {
        display: flex;
        margin: 0;
    }

    .ag-chart-menu-panel {
        @include ag-theme-rtl(
            (
                border-left: solid 1px,
            ),
            $color-param: border-color
        );

        @include ag-color-property(background-color, control-panel-background-color);
    }
}
