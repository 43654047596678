@import "./colors";
@import "./typography";

// Container elements
// https://dl.dropboxusercontent.com/u/73672306/BEEVA/Gas_Natural/Pantallas/GE_Elementos_contenedores.html

$wrapper-bg: #fff;
$wrapper-border: #999;
$wrapper-shadow: rgba(#ccc, 0.5);
$wrapper-fixed-width: 1000px;

// $wrapper-header-bg: #f5f5f5;
$wrapper-header-bg: #004368;
$wrapper-header-border: #f7f7f7;
$wrapper-header-inner-shadow: #fff;
$wrapper-header-outer-shadow: #e4e4e4;
$wrapper-header-padding: 0 15px 0 20px;
$wrapper-header-fixed-height: 58px;

$wrapper-body-padding: 30px 20px;

// $wrapper-footer-height: 70px;
$wrapper-footer-separator: #999;
$wrapper-footer-padding: 20px;

@mixin page-header() {
  padding: 22px 0 12px;
  font-size: 14px;
  margin-right: 22px;
}

@mixin floating-wrapper($width: dynamic, $positioning: aboslute) {
  background: $wrapper-bg;
  border: 2px solid $wrapper-border;
  border-radius: 2px;
  box-shadow: 2px 2px 2px $wrapper-shadow;
  padding: 1px;
  margin-top: 2em;
  max-height: 93%;
  @if $width == fixed {
    width: $wrapper-fixed-width;
    @if $positioning == absolute {
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: $wrapper-fixed-width / -2;
    }
  }
}

@mixin floating-wrapper-header($height: dynamic) {
  background: $wrapper-header-bg;
  border-bottom: 1px solid $wrapper-header-border;
  box-shadow: inset 0 1px 1px $wrapper-header-inner-shadow, 0 2px 2px $wrapper-header-outer-shadow;
  padding: $wrapper-header-padding;
  overflow: hidden;
  @if $height == fixed {
    height: $wrapper-header-fixed-height;
  }
}

@mixin floating-wrapper-header-title {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  padding: 0;
  align-self: center;
  justify-content: flex-start;
  flex-grow: 0.5;

  @include title-container;
}

@mixin floating-wrapper-header-actions {
  margin-top: 18px;
  align-self: center;
  justify-content: flex-end;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 10px;
  }

  & > * {
    color: $bdr-pumpkin;
    &:hover {
      color: $bdr-pumpkin;
    }
  }
}

@mixin floating-wrapper-body {
  padding: $wrapper-body-padding;
}

@mixin floating-wrapper-footer {
  // height: $wrapper-footer-height;
  border-top: 1px solid $wrapper-footer-separator;
  padding: $wrapper-footer-padding;
}

@mixin vignette($padding) {
  // border: 2px solid #e6e6e6;
  border: 2px solid $bdr-second-pumpkin;
  box-shadow: 0 0 0 5px $bdr-second-pumpkin;
  background-color: $bdr-white;
  display: inline-block;
  border-radius: 50%;
  padding: $padding;
  position: relative;

  &::after {
    content: "";
    border: 5px solid transparent;
    // border-top-color: #eeeeee;
    border-top-color: $bdr-second-pumpkin;
    bottom: -17px;
    position: absolute;
    left: calc(50% - 5px);
  }
}

@mixin grid-wrapper {
  height: calc(100% - 173px);
  width: calc(100% - 6px);
  position: relative;
}
@mixin app-wrapper-loading {
  z-index: 1000;
}
@mixin wrapper-loading {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  &::after,
  &::before {
    content: "";
    flex: 1;
  }
  .loading-wrapper {
    flex: 1;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    background-color: $bdr-dark-blue;
    &:not(.loading-first-load) {
      border: 1px solid #e6e6e6;
      // background: #fff;
    }
  }
  .vignette {
    margin: 20px 0 50px;

    @include vignette(27px);
    .vignette-icon {
      // color: #bdbdbd;
      color: $bdr-pumpkin;
    }
  }
  .loading-title {
    @include title-menu;
    color: $bdr-white;
  }
  .loading-desc {
    @include text;
    color: $bdr-white;
  }
}

@mixin wrapper-loading-relative {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  &::after,
  &::before {
    content: "";
    flex: 1;
  }
  .loading-wrapper {
    flex: 1;
    border: none;
    margin: 0 auto;
    text-align: center;
  }
  .vignette {
    margin: 20px 0 10px;

    @include vignette(35px);
    .vignette-icon {
      color: #bdbdbd;
    }
  }
  .loading-title {
    @include title-menu;
  }
  .loading-desc {
    @include text;
  }
}

@mixin contextualmenu-factory {
  &:not(.no-layer) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 9998;
  }
}

@mixin add-dialog-header {
  @include floating-wrapper-header();
  min-width: 20rem;
  min-height: 3rem;
  // margin-bottom: 3px; // Necesario para dar espacio a la sombra.
  margin-bottom: 0;
  cursor: move;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  box-shadow: none;
  &.fixed {
    height: auto;
    cursor: default;
  }
  .header-title {
    @include floating-wrapper-header-title;
    margin: 0;
    color: $bdr-white;
    .button {
      margin-left: 0.5rem;
    }
  }
  .header-buttons {
    @include floating-wrapper-header-actions;
    margin: 0;
    cursor: default;
    .button-drag {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
  input {
    @include title-container;
    border: none;
    border-bottom: solid 2px;
    min-width: 353px;
    margin: 0;
    &.error {
      color: $red;
      border-color: $red;
    }
  }
}
