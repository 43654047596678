@import "./colors";

@mixin material-checkbox-color($color: $bdr-pumpkin) {
  ::ng-deep mat-checkbox {
    &.mat-checkbox-checked {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          background-color: $color;
        }
      }
    }
    &.mat-checkbox-indeterminate {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          background-color: $color;
        }
      }
    }
  }
}
