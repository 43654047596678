/* You can add global styles to this file, and also import other style files */
$header-background-color: #f1f1f1;
$header-text-color: #6b6b6b;

$bdr-min-width-cell: 120px;
$bdr-padding-cell: 10px;
$header-row-count: 1;

@import "./app/styles/colors";
@import "./app/styles/typography";
@import "./app/styles/wrappers";
@import "./app/styles/mixins";

$font-family: $arial;

// Angular Material Styles
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

// AG Grid Tables
@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "src/assets/ag-grid-styles/ag-theme-balham/sass/ag-theme-balham.scss";

// SlickGrid table

@import "../node_modules/angular-slickgrid/styles/sass/slick-default-theme";
@import "../node_modules/angular-slickgrid/styles/sass/slick-grid";
@import "../node_modules/angular-slickgrid/styles/sass/slick-controls";
@import "../node_modules/angular-slickgrid/styles/sass/slick-editors";
@import "../node_modules/angular-slickgrid/styles/sass/slick-plugins";
@import "../node_modules/angular-slickgrid/styles/sass/slick-component";
@import "../node_modules/angular-slickgrid/styles/sass/slick-bootstrap";
@import "../node_modules/angular-slickgrid/styles/sass/ui-autocomplete";

$header-padding-top: 10px;
$header-padding-bottom: 15px;
$header-padding-right: 4px;
$header-padding-left: 4px;
.slick-header-column {
  padding: $header-padding-top $header-padding-right $header-padding-bottom $header-padding-left !important;
}

$filter-placeholder-font-family: "FontAwesome" !important;

input.search-filter {
  font-family: $filter-placeholder-font-family;
}
.search-filter {
  input {
    font-family: $filter-placeholder-font-family;
    &.compound-input {
      border-radius: 0 !important;
      border-left: none;
    }
  }
  input.compound-slider {
    border-left: none !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.hidden {
  visibility: hidden;
}
h2 {
  font-size: 1.4rem;
  font-weight: bold;
  color: $bdr-header-bg;
  margin-right: 0.5rem;
}

p {
  margin-top: 0;
  color: $bdr-gray-darkest;
}

.flex-grid {
  div:focus {
    outline: transparent;
  }
}

input:focus,
textarea:focus {
  outline: none;
}

input.inline {
  background: transparent;
  border-bottom: solid 1px;
}

.flex-grid.wj-content {
  border-radius: 0;
  border: none;
}
.flex-grid [wj-part="fcells"],
.flex-grid [wj-part="rh"] {
  border-bottom: 1px solid #e2e2e2;
}

// BDR-2369 Fix última celda cuando ajustamos margen
.flex-grid {
  .wj-row {
    .wj-cell:last-child {
      overflow-wrap: break-word;
      white-space: normal;
    }
  }
}

.flex-grid [wj-part="rh"],
.flex-grid .wj-topleft .wj-cell,
.flex-grid .wj-topleft > .wj-row > .wj-cell.wj-header,
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header,
.flex-grid .wj-topleft .wj-row .grid-header-select-menu {
  width: 35px !important;
  border-left: solid 1px #e6e6e6;
  // border-right: solid 1px #e6e6e6;
}
.flex-grid [wj-part="tl"],
.flex-grid [wj-part="bl"],
.flex-grid .wj-topleft,
.flex-grid .wj-topleft .wj-row {
  width: 35px !important;
}
.flex-grid [wj-part="tl"] {
  height: 30px !important;
}

.grid-wrapper > .flex-grid [wj-part="root"] {
  border-left: solid 1px #e6e6e6;
  // border-right: solid 1px #e6e6e6;
}
.flex-grid [wj-part="fcells"] {
  border-bottom: none;
}
.flex-grid [wj-part="ch"] {
  width: calc(100%) !important;
  // TODO-techdebt: FORCED (this should be calculated  by grid?)
  left: 35px !important;
}
.flex-grid [wj-part="ch"],
.flex-grid [wj-part="tl"],
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header,
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header,
.flex-grid .wj-topleft,
.flex-grid .wj-topleft > .wj-row > .wj-cell.wj-header {
  height: 32px !important;
  background: #eee;
  font-size: 12px;
}
.grid-wrapper > .flex-grid [wj-part="rh"],
.grid-wrapper > .flex-grid [wj-part="root"] {
  transition: margin-top 0.2s;
}
.flex-grid [wj-part="root"] {
  overflow-y: scroll !important;
  overflow-x: auto !important;
}
.grid-wrapper.search-active > .flex-grid [wj-part="rh"] {
  height: calc(100% - 80px) !important;
  margin-top: 32px;
}
.flex-grid [wj-part="ch"],
.flex-grid [wj-part="tl"] {
  border-bottom: 1px solid #d2d2d2;
}
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header:not(:first-child) {
  border-left: 1px solid #fff;
}
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header {
  border-right-color: #e2e2e2;
}
.flex-grid .wj-topleft > .wj-row > .wj-cell.wj-header,
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header {
  border-bottom: 0;
}
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header > div,
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header > div > div {
  height: 100%;
}
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header > div {
  padding: 0;
}
.flex-grid .wj-colheaders > .wj-row > .wj-cell.wj-header > div > div {
  padding: 0 10px;
  line-height: 34px;
  color: #666;
  font-size: 12px;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  overflow: hidden;
}
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header,
.flex-grid .wj-cells > .wj-row > .wj-cell {
  line-height: 30px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #666;
  background: #fff;
}

.flex-grid .wj-cells[wj-part="cells"] {
  border-right: solid 1px #e2e2e2;
  // TODO: Forced
  left: 35px !important;
}

.flex-grid .wj-cells,
.flex-grid .wj-rowheaders {
  margin-top: 1px;
}
.flex-grid .wj-topleft > .wj-row > .wj-cell.wj-header {
  border-right: 0;
}
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header {
  background: #fff;
  border-right: 0;
  padding: 0 0 0 10px;
}

.flex-grid .wj-cell {
  border-bottom-color: #e2e2e2;
  padding: 0;
  // overflow: hidden;
  & > div {
    padding: 0 10px;
  }
}

.flex-grid .wj-cell.wj-header.wj-frozen-col {
  border-right: 3px solid #d2d2d2;
}
.flex-grid .wj-cell.wj-frozen.wj-frozen-col {
  border-right: 3px solid #e2e2e2;
}

/* quito lineas verticales entre celdas del grid */
.flex-grid .wj-cell:not(.wj-header) {
  border-right: none;
  .disabled {
    display: block;
  }
}

.flex-grid .wj-state-selected,
.flex-grid .wj-state-multi-selected {
  background: transparent;
  color: #444444;
}

/* fondo de filas seleccionadas */
.wj-cell.selected,
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header.active {
  background: #d9e2e8 !important;
  box-shadow: inset 0px 1px 0px 0px #fff, inset 0px -1px 0px 0px #fff;
}
.flex-grid .wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
  background: #fff;
}
.flex-grid .wj-cell:not(.wj-header):not(.wj-group),
.flex-grid .wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(.wj-state-selected):not(.wj-state-multi-selected),
.flex-grid .wj-cell.wj-alt:not(.wj-header):not(.wj-group) {
  &.highlight,
  &.row-highlight.col-highlight {
    font-weight: bold;
    color: $real-black;
  }
}

.flex-grid .wj-topleft .wj-header {
  display: flex;
  align-items: center;
}
.flex-grid .wj-topleft .wj-row .grid-header-select-menu::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px 2px #eee;
  background: #fff;
  transform: translateY(9px);
  cursor: pointer;
  margin-top: -4px;
}

.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px 2px #eee;
  background: #fff;
  margin-top: 4px;
  cursor: pointer;
}
// .no-selectbox .flex-grid .wj-rowheaders > .wj-cell.wj-header::before {
//   display: none;
// }
.flex-grid.fast-search > div {
  padding-top: 32px;
  max-height: calc(100% - 32px) !important;
}
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header.active::before {
  border-color: #999;
}
.flex-grid .wj-rowheaders > .wj-row > .wj-cell.wj-header.active::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -17px;
  margin-left: 5px;
  background: #e98300;
  cursor: pointer;
}

.caret-asc {
  display: inline-block;
  transform: rotateZ(30deg);
}

.caret-desc {
  display: inline-block;
  transform: rotateZ(-30deg);
}

@mixin grid-colors-mixin($color) {
  &::before {
    border-color: $color;
  }
  &::after {
    border-top-color: $color;
    border-right-color: $color;
  }
}
$color1: #375623;
$color2: #a9d08e;
$color3: #e2efda;
$color4: #ffe699;
$color5: #ffc000;
$color6: #ce1b24;

.wj-cell {
  overflow: unset;
}

.wj-cell.color:hover {
  overflow: visible;
}

.wj-cells > .wj-row > .wj-cell.color {
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";

    width: 2.2rem;
    height: 1.1rem;
    border-radius: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    align-self: flex-start;

    margin-top: 10px;
    margin-left: -10px;
  }
  &.color_1 {
    @include grid-colors-mixin($color1);
  }
  &.color_2 {
    @include grid-colors-mixin($color2);
  }
  &.color_3 {
    @include grid-colors-mixin($color3);
  }
  &.color_4 {
    @include grid-colors-mixin($color4);
  }
  &.color_5 {
    @include grid-colors-mixin($color5);
  }
  &.color_6 {
    @include grid-colors-mixin($color6);
  }
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  min-height: 5rem;
  background-color: $bdr-scrollbar;
  border: 6px solid #fafafa;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 18px;
  border: 5px solid #fafafa;
  background-color: $bdr-scrollbar;
}

.clickable {
  cursor: pointer;
}

.title-main {
  @include title-main;
}

.icon-header {
  color: $bdr-gray-dark;
}

.validations--ela-detail {
  margin-left: 2px;
}

.fake-icon {
  background-color: $bdr-fake-icon-bg;
  border: solid 1px $bdr-fake-icon-bg;
  color: $bdr-white;
  padding: 0.2rem 0.5rem;
  border-radius: 0;
  font-weight: bold;
  font-size: 12px;
  vertical-align: middle;

  &.manual-validations {
    background-color: transparent;
    color: $bdr-fake-icon-manual-color;
    border: solid 1px $bdr-fake-icon-manual-color;
    &:hover,
    &.hover {
      color: $bdr-fake-icon-manual-hover-color;
      background-color: $bdr-fake-icon-manual-hover-bg;
    }
  }
}

a.fake-icon:hover {
  background-color: $bdr-fake-icon-bg-hover;
}

.secondary-link,
.link {
  font-weight: bold;
  @include link;

  &.disabled {
    color: $bdr-gray-dark;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
}

.secondary-link {
  color: $bdr-gray-cancel;
}

.draggable-dialogs-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100vw;
  display: flex;
  justify-content: flex-end;
}

.not-display {
  display: none;
}
.block {
  display: block;
}

.a-l {
  text-align: left;
}

.a-c {
  text-align: center;
}

.a-r {
  text-align: right;
}
/* Component common styles */

bdr-dialog-wrapper {
  .body {
    @include floating-wrapper-body;
    display: flex;
    flex-grow: 1;
    padding: 0;
    overflow: auto;
    .body-wrapper {
      padding: 1.5rem 1.5rem 0;
      // max-height: 30rem;
      overflow: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      position: relative;
      p:not(.input-error-caption) {
        margin-top: 0;

        &.title {
          font-size: 1.3rem;
        }
        &.highlight {
          font-weight: bold;
          font-size: 1.5rem;
          color: $black;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  p {
    margin-top: 0;
    color: $dialog-dark-grey;
  }
  .bottom-actions {
    display: flex;
    flex-shrink: 0;
    padding: 1rem 0;
    border-top: 1px solid $dialog-dark-grey;
    .main-actions {
      flex-shrink: 0;
      padding: 0 1rem;
      box-shadow: 1px 0 0 0 $bdr-gray-dark;
      .main-actions-options-list-wrapper {
        margin-top: 1rem;
        white-space: nowrap;
        .main-actions-options-list {
          max-height: 10rem;
          position: relative;
          overflow: auto;
          margin-top: 1rem;
          .main-actions-options-list-select {
            width: 100%;
          }
          &.hidden {
            display: none;
          }
        }
      }
    }
    .optional-actions {
      flex-grow: 1;
      padding: 0 1rem;
    }
  }
  .footer {
    padding: 1rem;
    text-align: right;
    .link {
      margin-right: 0.5rem;
    }
  }
}

.bdr-card {
  margin: 20px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $bdr-text-disabled;
  background-color: $bdr-white;
  padding: 20px;
  margin-bottom: 20px;
  &__description {
    margin: 20px 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $bdr-text-disabled;
    background-color: $bdr-white;
    padding: 10px 10px 0px 10px;
    margin-bottom: 20px;
  }
}

.bdr-btn {
  border: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  font-size: 0.875rem;
  bdr-icon {
    margin-right: 15px;
  }
  &-white {
    background-color: $bdr-white;
    color: $bdr-btn-grey;
  }
  &-blue {
    background-color: $bdr-dark-blue;
    color: $bdr-white;
  }
  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.backdrop {
  position: fixed;
  top: calc(0px + 126px);
  left: 0;
  height: 100vh;
  width: 100vw;
  background: transparent;
  z-index: 1;
}

.uucc-table { 
  overflow: auto;
  height: 500px;
  
  .grip-handle {
    &.rangeDrag {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .mat-table {
    border-collapse: collapse;
    width: 100%;
    .mat-header-cell {
      border: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 0;
      }
    }
    .mat-header-cell,
    .mat-cell {
      @include material-checkbox-color();
    }
    .mat-header-cell {
      min-width: $bdr-min-width-cell;
      text-align: center;
      
      b {
        font-weight: bold;
        color: $bdr-header-table-color-text;
      }
    }
    .mat-row {
      height: 30px;
      &:nth-child(even) {
        background-color: $bdr-table-even-color;
      }
      &:nth-child(odd) {
        background-color: $bdr-table-odd-color;
      }
      &.invalid-row {
        background-color: #ffaeaa;
      }
      &.incomplete-row {
        background-color: #ffffcb;
      }
      .mat-cell {
        min-width: $bdr-min-width-cell;
        text-align: center;
        padding: 0 10px !important;
        &.no-editable {
          background: $bdr-table-even-color;
        }
        &--text-align {
          text-align: left;
        }
        input {
          border: 0;
          width: 100%;
          background-color: inherit;
        }
      }
    }
    .mat-row:hover {
      background-color: #F0F5FB;
    }

    .mat-row-warning {
      background-color: #ffffcb;
    }
    .mat-row-warning:hover {
      background-color: #F0F5FB;
    }

    .mat-row-error {
      background-color: #FF7276;
    }
    .mat-row-error:hover {
      background-color: #F0F5FB;
    }
  }
}

.bdr-table {
  overflow: auto;
  max-height: 500px;
  .grip-handle {
    &.rangeDrag {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .mat-table {
    border-collapse: collapse;
    width: 100%;
    .mat-header-cell {
      border: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 0;
      }
    }
    .mat-header-cell,
    .mat-cell {
      @include material-checkbox-color();
    }
    .mat-header-cell {
      min-width: $bdr-min-width-cell;
      text-align: center;
      &.mat-table-sticky {
        z-index: 1 !important;
      }
      b {
        font-weight: bold;
        color: $bdr-header-table-color-text;
      }
    }
    .mat-row {
      height: 30px;
      &:nth-child(even) {
        background-color: $bdr-table-even-color;
      }
      &:nth-child(odd) {
        background-color: $bdr-table-odd-color;
      }
      &.invalid-row {
        background-color: #ffaeaa;
      }
      &.incomplete-row {
        background-color: #ffffcb;
      }
      .mat-cell {
        min-width: $bdr-min-width-cell;
        text-align: center;
        padding: 0 10px !important;
        &.no-editable {
          background: $bdr-table-even-color;
        }
        &--text-align {
          text-align: left;
        }
        input {
          border: 0;
          width: 100%;
          background-color: inherit;
        }
      }
    }
    .mat-row:hover {
      background-color: #F0F5FB;
    }

    .mat-row-warning {
      background-color: #ffffcb;
    }
    .mat-row-warning:hover {
      background-color: #F0F5FB;
    }

    .mat-row-error {
      background-color: #FF7276;
    }
    .mat-row-error:hover {
      background-color: #F0F5FB;
    }
  }
}

.cdk-global-overlay-wrapper {
  justify-content: center;
  align-items: center;
  ::ng-deep mat-dialog-container {
    padding: 0;
    .container-dialog {
      display: flex;
      flex-direction: column;
      height: 100%;
      font-family: $arial;
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $bdr-dark-blue;
        height: 100px;
        &:empty {
          display: none;
        }
        h1 {
          color: $bdr-white;
          font-size: 24px;
          font-family: $arial;
        }
      }
      &__body {
        flex: 1;
        padding: 25px;
        ::ng-deep mat-form-field {
          width: 100%;
          .mat-form-field-infix {
            border: 0;
            display: flex;
            justify-content: space-between;
          }
          bdr-icon {
            cursor: pointer;
          }
        }
      }
      &__footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100px;
        &:empty {
          display: none;
        }
      }
    }
  }
}

// Material Input
::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $bdr-dark-blue !important;
}

::ng-deep.mat-form-field-underline {
  /*change color of underline*/
  background-color: $bdr-dark-blue !important;
}

::ng-deep.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $bdr-dark-blue !important;
}

.mat-tooltip {
  font-size: 20px;
}

::ng-deep .mat-select-panel {
  width: 100%;
  max-width: 800px !important;
}

.form-input {
  display: flex;
  align-items: center;
  &.editing {
    .mat-input-element {
      border-bottom: 1px solid $bdr-table-editable-cell !important;
      width: 100% !important;
    }
    bdr-icon {
      color: $bdr-table-editable-cell;
    }
  }
}

// Context menu ag-grid
.context-menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.context-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 50px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;

  &-text {
    &::after {
      font-family: "bdr_ico";
      content: "\e932";
    }
  }
}

// Para controlar el box-sizing de bootstrap.
* {
  box-sizing: content-box;
}
:host {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

bdr-grid {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .slick-header-column {
    text-transform: uppercase;
    margin-top: 6px;
  }
  .slick-header-button {
    color: $bdr-pumpkin;
  }

  .slick-header-button.fa.fa-plus-circle {
    font-size: 15px;
    padding: 6px 0px 0px 0px;
    color: rgb(15, 53, 92);
  }

  .slick-cell {
    &.highlighted {
      background: #728fa0;
      background: rgba(0, 0, 255, 0.2);
      transition: all 0.5s;
    }
  }

  .ui-state-default.slick-headerrow-column {
    text-align: inherit;
  }

  .slickgrid-container .slick-pane .slick-headerrow .slick-headerrow-columns .slick-headerrow-column input {
    width: 100%;
    font-family: $font-family;
  }

  .form-control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  :focus {
    border-color: #2298af;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 113, 152, 0.25);
  }

  .fake-hyperlink {
    cursor: pointer;
    font-weight: bold;
    color: #ef8200;
    &:hover {
      text-decoration: underline;
    }
  }
}
