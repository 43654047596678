@font-face {
  font-family: "bdr_ico";
  src: url("./bdr_ico.eot?tqzyam");
  src: url("./bdr_ico.eot?tqzyam#iefix") format("embedded-opentype"), url("./bdr_ico.ttf?tqzyam") format("truetype"),
    url("./bdr_ico.woff?tqzyam") format("woff"), url("./bdr_ico.svg?tqzyam#bdr_ico") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "bdr_ico" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.icon-ico-settings:before {
  content: "\e94c";
}
.icon-ico-menu:before {
  content: "\e94e";
}
.icon-ico-masterdata:before {
  content: "\e94d";
}
.icon-ico-home:before {
  content: "\e94f";
}
.icon-ico-fastfilter:before {
  content: "\e936";
}
.icon-ico-legalizacion:before {
  content: "\e924";
}
.icon-ico-formularios:before {
  content: "\e912";
}
.icon-ico-ok-circle:before {
  content: "\e900";
}
.icon-ico-ko-circle:before {
  content: "\e901";
}
.icon-ico-actions:before {
  content: "\e902";
}
.icon-ico-calendar:before {
  content: "\e903";
}
.icon-ico-load:before {
  content: "\e904";
}
.icon-ico-menu4:before {
  content: "\e905";
}
.icon-ico-menu5:before {
  content: "\e906";
}
.icon-ico-plus:before {
  content: "\e907";
}
.icon-ico-tag:before {
  content: "\e908";
}
.icon-ico-unlink:before {
  content: "\e909";
}
.icon-ico-menu1:before {
  content: "\e90a";
}
.icon-ico-file:before {
  content: "\e90b";
}
.icon-ico-img:before {
  content: "\e90c";
}
.icon-ico-ko:before {
  content: "\e90d";
}
.icon-ico-link:before {
  content: "\e90e";
}
.icon-ico-lock:before {
  content: "\e90f";
}
.icon-ico-menu2:before {
  content: "\e910";
}
.icon-ico-menu3:before {
  content: "\e911";
}
.icon-ico-preview:before {
  content: "\e913";
}
.icon-ico-time:before {
  content: "\e914";
}
.icon-ico-unlock:before {
  content: "\e915";
}
.icon-ico-right:before {
  content: "\e916";
}
.icon-ico-close:before {
  content: "\e917";
}
.icon-ico-delete:before {
  content: "\e918";
}
.icon-ico-down:before {
  content: "\e919";
}
.icon-ico-filter:before {
  content: "\e91a";
}
.icon-ico-min:before {
  content: "\e91b";
}
.icon-ico-notify:before {
  content: "\e91c";
}
.icon-ico-process2:before {
  content: "\e91d";
}
.icon-ico-process3:before {
  content: "\e91e";
}
.icon-ico-select_up:before {
  content: "\e91f";
}
.icon-ico-drag:before {
  content: "\e920";
}
.icon-ico-less3:before {
  content: "\e921";
}
.icon-ico-user:before {
  content: "\e922";
}
.icon-ico-less2:before {
  content: "\e923";
}
.icon-ico-clickable:before {
  content: "\e925";
}
.icon-ico-comment:before {
  content: "\e926";
}
.icon-ico-left:before {
  content: "\e927";
}
.icon-ico-menu41:before {
  content: "\e928";
}
.icon-ico-ok:before {
  content: "\e929";
}
.icon-ico-search:before {
  content: "\e92a";
}
.icon-ico-select_down:before {
  content: "\e92b";
}
.icon-ico-version2:before {
  content: "\e92c";
}
.icon-ico-version3:before {
  content: "\e92d";
}
.icon-ico-less:before {
  content: "\e92e";
}
.icon-ico-up:before {
  content: "\e92f";
}
.icon-ico-bottom:before {
  content: "\e930";
}
.icon-ico-download:before {
  content: "\e931";
}
.icon-ico-duplicate:before {
  content: "\e932";
}
.icon-ico-email:before {
  content: "\e933";
}
.icon-ico-plus2:before {
  content: "\e934";
}
.icon-ico-plus3:before {
  content: "\e935";
}
.icon-ico-undo:before {
  content: "\e937";
}
.icon-ico-version:before {
  content: "\e938";
}
.icon-ico-validations:before {
  content: "\e939";
}
.icon-ico-next:before {
  content: "\e93a";
}
.icon-ico-place:before {
  content: "\e93b";
}
.icon-ico-alert:before {
  content: "\e93c";
}
.icon-ico-edit:before {
  content: "\e93d";
}
.icon-ico-max:before {
  content: "\e93e";
}
.icon-ico-menu31:before {
  content: "\e93f";
}
.icon-ico-menu34:before {
  content: "\e940";
}
.icon-ico-menu35:before {
  content: "\e941";
}
.icon-ico-process:before {
  content: "\e942";
}
.icon-ico-redo:before {
  content: "\e943";
}
.icon-ico-top:before {
  content: "\e944";
}
.icon-ico-eyenot:before {
  content: "\e945";
}
.icon-ico-send:before {
  content: "\e946";
}
.icon-ico-eye:before {
  content: "\e947";
}
.icon-ico-menu32:before {
  content: "\e948";
}
.icon-ico-menu33:before {
  content: "\e949";
}
.icon-ico-save:before {
  content: "\e94a";
}
.icon-ico-update:before {
  content: "\e94b";
}
.icon-ico-copy:before {
  content: "\e92c";
}
.icon-ico-key:before {
  content: "\e98d";
}
