@import "../ag-theme-balham-mixin";

// LEGACY THEME FILE
// This mixin is for backwards compatibility with older apps that use global variables.
// Including this file emits CSS for the Balham theme. New apps should @import ag-theme-balham-mixin.scss
// and @include ag-theme-balham($params), instead of using this file.

@import "./ag-theme-balham-define-legacy-vars";

.ag-theme-balham {
    @include ag-theme-balham(ag-theme-base-create-params-from-legacy-vars($ag-theme-balham-default-params));
    @include ag-v22-to-v23-compatibility-mode();
}
